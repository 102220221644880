<script setup lang="ts">
import dayjs from "dayjs";

const { t } = useT();
const { open } = useTaoModals();

const { piggyData, isFullStatus, readyToBreak, handlePiggyClick, refreshPiggyData } = usePiggy({ open });

const title = computed(() => (isFullStatus.value ? t("Break Your Piggy Bank!") : t("Piggy bank")));

const buttonText = computed(() =>
	readyToBreak.value
		? t("Collect Now for $", { minPromoCost: piggyData.value?.promoOffersPresets?.money ?? 0 })
		: t("Feed Piggy")
);

const { durationLeft, reset } = useCountdown({
	timestamp: piggyData.value?.availableTimeToBreak || "",
	formatToken:
		dayjs(piggyData.value?.availableTimeToBreak ?? "").diff(dayjs(), "d") >= 1
			? "DD[ d ] HH[ h ]mm[ m ]"
			: "HH[ h ]mm[ m ]ss[ s ]",
	isOptimized: true,
	onCountdownStop: refreshPiggyData
});

watch(
	() => piggyData.value?.availableTimeToBreak,
	(value) => {
		if (value) {
			reset(value);
		}
	}
);
</script>
<template>
	<MBanner
		class="piggy"
		image="/nuxt-img/piggy-bank/piggy-bank-web-bg-new.jpg"
		image-mobile="/nuxt-img/piggy-bank/piggy-bank-mob-bg-new.jpg"
		second-image="/nuxt-img/piggy-bank/piggy-bank-web-front-new.png"
		second-image-mobile="/nuxt-img/piggy-bank/piggy-bank-mob-front-new.png"
	>
		<template #default>
			<AText :size="{ full: 20, lg: 16 }" :modifiers="['uppercase', 'italic', 'medium']">
				{{ title }}
			</AText>
			<AText class="title" :size="{ full: 36, lg: 28 }" :modifiers="['left', 'uppercase', 'bold']" as="div">
				<i18n-t v-if="isFullStatus" keypath="It's full - you can {br} collect free SC!">
					<template #br>
						<br />
					</template>
				</i18n-t>
				<i18n-t v-else keypath="Get extra coins {br} for every play!">
					<template #br>
						<br />
					</template>
				</i18n-t>
			</AText>
		</template>
		<template #description>
			<div class="d-flex flex-column gap-1">
				<MPrizeFund v-if="isFullStatus" variant="entries" icon="28/secret-coin" :icon-size="26">
					<AText type="h4" as="h4">
						{{ numberFormat(piggyData?.maxAmount ?? 0) }}
					</AText>
					<AText type="xx-small" :modifiers="['uppercase']">
						<i18n-t keypath="Free {br} secret coins">
							<template #br>
								<br />
							</template>
						</i18n-t>
					</AText>
				</MPrizeFund>
				<MPiggybankProgressBar
					v-else
					class="progress"
					:complete="piggyData?.moneyBoxAmount ?? 0"
					:goal="piggyData?.maxAmount ?? 0"
				/>
			</div>
		</template>
		<template #actions>
			<AText v-if="readyToBreak && piggyData?.availableTimeToBreak" :size="14" class="color-gray-50">
				<i18n-t keypath="Break in {time}">
					<template #time>
						<AText :size="14" :modifiers="['bold']" class="color-gray-50">{{ durationLeft }}</AText>
					</template>
				</i18n-t>
			</AText>
			<AButton v-else variant="primary" size="lg" :modifiers="['wide']" @click="handlePiggyClick">
				{{ buttonText }}
			</AButton>
		</template>
	</MBanner>
</template>

<style scoped lang="scss">
.piggy {
	height: 430px;

	@include media-breakpoint-up(md) {
		height: 300px;
	}

	.title {
		margin-top: 2px;
		text-align: center;

		@include media-breakpoint-up(md) {
			margin-top: 16px;
			text-align: left;
		}
	}

	&:deep(.prize) {
		@include media-breakpoint-down(md) {
			justify-content: center;
		}
	}

	.progress {
		width: 100%;

		&:deep(.progress-wrapper) {
			background: var(--color-tertiary-700);
		}
	}

	&:deep(.app-banner__desc) {
		width: 100%;
		margin: 4px 0 16px;

		@include media-breakpoint-up(md) {
			width: 327px;
			margin: 16px 0 24px;
		}
	}

	&:deep(.app-banner__actions) {
		width: 100%;

		@include media-breakpoint-up(md) {
			width: 240px;
			align-items: flex-start;
		}
	}
}
</style>
